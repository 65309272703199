// eslint-disable-next-line no-shadow
import { track } from '@eventbrite/datalayer-library';
import { Location } from '@eventbrite/discover-utils';
import { setWindowLocation } from '@eventbrite/http';
import { gettext } from '@eventbrite/i18n';
import { $FixMe } from '@eventbrite/ts-utils';
import filter from 'lodash/filter';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import replace from 'lodash/replace';
import size from 'lodash/size';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import {
    GA_HOME_CATEGORY,
    GA_HOME_FEED_CATEGORY,
    GA_SEE_MORE_ACTION,
    GA_SWITCH_TAB,
} from '../../../../constants/analytics';
import { SMALL_CITY_FALLBACK } from '../../../../constants/constants';
import {
    ALL_TAB,
    FOR_YOU,
    SEASONAL_TAB,
    TABS_CONFIG,
} from '../../../../constants/tabConfig';
import {
    BucketsContent,
    DiscoverState,
    EntityContext,
    OrganizerProfile,
    OrganizerProfiles,
} from '../../../../types/index';
import { buildLocationData } from '../../../../utils/location';
import { HEAP_BUCKET_ONLOAD } from '../../constants/analytics';
import {
    getIcon,
    retrieveHomeFeedState,
    transformBucketsContent,
    transformSearchContent,
} from '../../utils';
import { updateContent, updateTabKey } from '../content/actions';
import {
    fetchCityBrowseEvents,
    getUserPersonalizedContent,
    getUserSavedEvents,
    searchEventsBy,
    searchEventsWithParameters,
} from './api';
import {
    setCityBrowseCallAsFailed,
    setCityBrowseCallAsOngoing,
    setCityBrowseCallAsSuccess,
} from './cityBrowseActions';
import {
    setSearchCallAsFailed,
    setSearchCallAsOngoing,
    setSearchCallAsSuccess,
    setSearchCallAsTimeout,
} from './searchActions';

import { interleaveTwoArrays } from '@eventbrite/ads';
import { DestinationEvent, FormattedEvent } from '@eventbrite/event-renderer';
import {
    getNavigatorLastRecentSearchTerms,
    getNavigatorLastRecentViews,
} from '@eventbrite/personalization';

let bucketsTrackedByHeap: string[] = [];
const INCREASED_REGULAR_TAB_AD_SLOTS = [1, 6, 9, 10];

export const trackTabChange = ({
    tabKey,
    loggedIn = true,
}: {
    tabKey: string;
    loggedIn?: boolean;
}) => ({
    type: 'CHANGE_TAB',
    meta: {
        analytics: {
            category: loggedIn ? GA_HOME_FEED_CATEGORY : GA_HOME_CATEGORY,
            action: GA_SWITCH_TAB,
            label: tabKey,
        },
    },
});

const trackSeeMore = ({
    bucketKey,
    tabKey,
    loggedIn = true,
}: {
    bucketKey: number;
    tabKey: number;
    loggedIn: boolean;
}) => ({
    type: 'SEE_MORE_ACTION',
    meta: {
        analytics: {
            category: loggedIn ? GA_HOME_FEED_CATEGORY : GA_HOME_CATEGORY,
            action: GA_SEE_MORE_ACTION,
            label: `${bucketKey}/${tabKey}`,
        },
    },
});

export const clickOnSeeMore =
    (url: string, bucketKey: number) =>
    (dispatch: Function, getState: Function) => {
        const state = getState();
        const {
            user: { isAuthenticated },
            content: {
                browseState: { tabKeyLabel },
            },
        } = state;

        dispatch(
            trackSeeMore({
                bucketKey,
                tabKey: tabKeyLabel,
                loggedIn: isAuthenticated,
            }),
        );

        setWindowLocation(url);
    };

export const UPDATE_EVENT_SAVED_BY_YOU = 'UPDATE_EVENT_SAVED_BY_YOU';

export const UPDATE_LIKES_EVENT_BUCKET = 'UPDATE_LIKES_EVENT_BUCKET';

const finishFetchEditorsPickEvents = (
    dispatch: Function,
    entities: $FixMe,
    entityContext: EntityContext[],
    fetchSuccess = true,
) => {
    dispatch(
        updateContent({
            entities,
            entityContext,
        }),
    );

    if (fetchSuccess) {
        dispatch(setCityBrowseCallAsSuccess());
    } else {
        dispatch(setCityBrowseCallAsFailed());
    }
};

export const fetchEditorsPickEvents =
    ({ locationData }: { locationData?: Location } = {}) =>
    (dispatch: Function, getState: Function) => {
        const { location } = getState();

        dispatch(setCityBrowseCallAsOngoing());

        const _locationData = isEmpty(locationData) ? location : locationData;
        const { placeId, isOnline } = _locationData;

        if (placeId && !isOnline) {
            return fetchCityBrowseEvents(placeId)
                .then(({ buckets }: { buckets: BucketsContent }) => {
                    let { entities, entityContext } =
                        transformBucketsContent(buckets);

                    const smallCityFallbackBucket: $FixMe = find(
                        entityContext,
                        {
                            key: SMALL_CITY_FALLBACK,
                        },
                    );

                    // When small_city_fallback, fetch more using the search endpoint events and append them to the same bucket
                    if (
                        smallCityFallbackBucket &&
                        size(smallCityFallbackBucket.events) < 32
                    ) {
                        return searchEventsBy(placeId)
                            .then((response: any) => {
                                const { entities: newEntities, ids } =
                                    transformSearchContent(response);

                                entities = {
                                    ...entities,
                                    ...newEntities,
                                };

                                entityContext = filter(
                                    entityContext,
                                    ({ key }) => key !== SMALL_CITY_FALLBACK,
                                );
                                entityContext = [
                                    ...entityContext,
                                    {
                                        ...smallCityFallbackBucket,
                                        events: uniq([
                                            ...ids,
                                            ...smallCityFallbackBucket.events,
                                        ]),
                                    },
                                ];

                                finishFetchEditorsPickEvents(
                                    dispatch,
                                    entities,
                                    entityContext,
                                );
                            })
                            .catch(() => {
                                dispatch(setCityBrowseCallAsFailed());
                                return [];
                            });
                    }

                    return finishFetchEditorsPickEvents(
                        dispatch,
                        entities,
                        entityContext,
                    );
                })
                .catch(() => {
                    dispatch(setCityBrowseCallAsFailed());
                    return [];
                });
        }

        const { entities, entityContext } = transformBucketsContent([]);

        return finishFetchEditorsPickEvents(
            dispatch,
            entities,
            entityContext,
            false,
        );
    };

export const UPDATE_TAB_CONTENT = 'UPDATE_TAB_CONTENT';

export const buildEventSearch = ({
    flatBucket,
    tabKey,
}: {
    flatBucket?: $FixMe;
    tabKey: string;
}) => {
    const eventSearch = TABS_CONFIG[tabKey]?.eventSearch;

    // eventSearch is empty when is called from switch location
    const _eventSearch = isEmpty(eventSearch)
        ? get(flatBucket, 'eventSearch', { dates: ['current_future'] })
        : eventSearch;

    if (
        tabKey === SEASONAL_TAB &&
        !isEmpty(_eventSearch) &&
        !isString(_eventSearch.q)
    ) {
        _eventSearch.q = _eventSearch.q ? _eventSearch.q.toString() : '';
    }

    return _eventSearch;
};

const getAndUpdateTabKeyLabel =
    ({ tabKey }: { tabKey: string }) =>
    (dispatch: Function, getState: Function) => {
        const {
            user: { isAuthenticated },
            content: { seasonal } = {},
        }: {
            user: { isAuthenticated: boolean };
            content: { seasonal?: any };
        } = getState();

        if (!TABS_CONFIG[tabKey]) {
            return;
        }

        let { tabKeyLabel } = TABS_CONFIG[tabKey];
        let contentLabel = tabKeyLabel;

        // get actual TabKey to check if is a real tab change or it is tab redirected from logged in/out
        const actualTabKey = retrieveHomeFeedState({
            seasonalContent: seasonal,
            isAuthenticated,
        }).tabKey;

        if (actualTabKey !== tabKey) {
            if (tabKey === SEASONAL_TAB) {
                const seasonalTabLabel = get(
                    seasonal,
                    'byDate.key',
                    get(seasonal, 'byDate.tabName', ''),
                );
                tabKeyLabel = seasonalTabLabel ? seasonalTabLabel : tabKeyLabel;
            }

            dispatch(
                trackTabChange({
                    loggedIn: isAuthenticated,
                    tabKey: replace(tabKeyLabel, ' ', ''),
                }),
            );
            dispatch(updateTabKey({ tabKey, tabKeyLabel }));
            contentLabel = `on_tab_change-${tabKeyLabel}`;
        }

        return contentLabel;
    };

const fetchEventsForRegularTab =
    ({
        locationData,
        tabKey = ALL_TAB,
    }: {
        locationData: Location;
        tabKey: string;
    }) =>
    (dispatch: Function, getState: Function) => {
        dispatch(setSearchCallAsOngoing());

        const {
            app: { featureFlags },
            location,
            content: { flatBucket },
        }: DiscoverState = getState();

        const _locationData = buildLocationData({ location, locationData });
        const { placeId, bbox, isOnline } = _locationData;

        const _eventSearch = buildEventSearch({
            flatBucket,
            tabKey,
        });

        dispatch(getAndUpdateTabKeyLabel({ tabKey }));

        if (
            !featureFlags.disableCityBrowseOnFeed &&
            tabKey === ALL_TAB &&
            featureFlags.launchEditorsPickInFeed
        ) {
            dispatch(fetchEditorsPickEvents({ locationData: _locationData }));
        }

        const adSlots = INCREASED_REGULAR_TAB_AD_SLOTS;

        return searchEventsWithParameters({
            placeId: placeId || '',
            bbox: bbox || {},
            eventSearch: _eventSearch,
            isOnline: isOnline || false,
            slots: adSlots.length,
        })
            .then(({ events }: any) => {
                const allEvents = interleaveTwoArrays<DestinationEvent>(
                    events.results,
                    events.promoted_results,
                    adSlots,
                );

                dispatch({
                    type: UPDATE_TAB_CONTENT,
                    payload: {
                        events: allEvents,
                        eventSearch: _eventSearch,
                    },
                });

                dispatch(setSearchCallAsSuccess());
            })
            .catch((error) => {
                if (error.name === 'AbortError') {
                    dispatch(setSearchCallAsTimeout());
                } else {
                    dispatch(setSearchCallAsFailed());
                }
            });
    };

/**
 * @name fetchEventsForYouTab
 * @param { tabKey }: an object containing a string representation of the tabKey
 * @description fetches recommended events from the personalized user content api
 */
const fetchEventsForYouTab =
    ({ tabKey, filters }: { tabKey: string; filters?: Array<object> }) =>
    async (dispatch: Function, getState: Function) => {
        dispatch(setSearchCallAsOngoing());

        const {
            user: { isAuthenticated },
            location,
            app: { locale },
        }: {
            user: { isAuthenticated: boolean };
            location: Location;
            app: { locale: string };
        } = getState();

        dispatch(getAndUpdateTabKeyLabel({ tabKey }));

        if (!isAuthenticated) {
            dispatch(setSearchCallAsSuccess());
            return dispatch({
                type: UPDATE_TAB_CONTENT,
                payload: {
                    events: [],
                },
            });
        }

        const entityContext: { [key: string]: EntityContext } = {};

        const formattedPreviousSearches = getNavigatorLastRecentSearchTerms(5);

        const views = getNavigatorLastRecentViews();

        const updatedFilters = [
            ...(Array.isArray(filters) ? filters : []),
            { name: 'place_id', value: location.placeId, payload: 'data' },
            {
                name: 'user_recent_searches',
                value: formattedPreviousSearches,
                payload: 'data',
            },
            { name: 'user_recent_views', value: views, payload: 'data' },
            { name: 'logged_in', value: isAuthenticated, payload: 'data' },
            {
                name: 'browse_surface',
                value: 'homepage_for_you',
                payload: 'data',
            },
        ];
        let recommendedEvents = { buckets: [] };
        try {
            recommendedEvents = await getUserPersonalizedContent(
                locale,
                updatedFilters,
                location,
            );
        } catch (error) {
            if (error.name === 'AbortError') {
                dispatch(setSearchCallAsTimeout());
                return;
            } else {
                dispatch(setSearchCallAsFailed());
                return;
            }
        }

        const buckets = recommendedEvents?.buckets || [];

        const entities: { [key: string]: FormattedEvent | OrganizerProfile } =
            {};

        if (!isEmpty(buckets)) {
            buckets.forEach(
                (bucket: {
                    key: string;
                    type: string;
                    name: string;
                    events?: FormattedEvent[];
                    profiles?: OrganizerProfiles;
                }) => {
                    if (bucket.events) {
                        entityContext[bucket.key] = {
                            key: bucket.key,
                            type: bucket.type,
                            name: bucket.name,
                            events: bucket.events.map(
                                (event: FormattedEvent) => event.id,
                            ),
                            icon: getIcon(tabKey, bucket.type),
                        };

                        bucket.events.forEach((event: FormattedEvent) => {
                            entities[event.id || ''] = event;
                        });
                    }
                    if (bucket.profiles) {
                        entityContext[bucket.key] = {
                            key: bucket.key,
                            type: bucket.type,
                            name: bucket.name,
                            profiles: bucket.profiles.map(
                                (profile: OrganizerProfile) => profile.id,
                            ),
                            icon: getIcon(tabKey, bucket.type),
                        };

                        bucket.profiles.forEach((profile: OrganizerProfile) => {
                            entities[profile.id || ''] = profile;
                        });
                    }

                    // only track a bucket load once
                    const isBucketTrackedByHeap = bucketsTrackedByHeap.find(
                        (bucketTracked: string) =>
                            bucket.name === bucketTracked,
                    );

                    if (
                        bucket.name &&
                        isAuthenticated &&
                        !isBucketTrackedByHeap
                    ) {
                        track({
                            eventName: HEAP_BUCKET_ONLOAD,
                            eventData: {
                                personalizationBucketName: bucket.name,
                                personalizationBucketKey: bucket.key,
                            },
                        });

                        bucketsTrackedByHeap = [
                            ...bucketsTrackedByHeap,
                            bucket.name,
                        ];
                    }
                },
            );

            dispatch(
                updateContent({
                    entityContext: Object.values(entityContext),
                    entities,
                }),
            );
            dispatch(setSearchCallAsSuccess());
        } else {
            dispatch(setSearchCallAsFailed());
        }
    };

/**
 * @name fetchUserSavedEvents
 * @description fetches saved/liked events for a given user that are sorted and returned
 * - Runs when the containing bucket component enters the viewport
 * @returns Event[] objects to be displayed in the home page For You tab
 */
export const fetchUserSavedEvents =
    () => async (dispatch: Function, getState: Function) => {
        const {
            user: { isAuthenticated },
            content: { entityContext },
        }: {
            user: { isAuthenticated: boolean };
            content: {
                entityContext: EntityContext[];
            };
        } = getState();
        const entities: { [key: string]: FormattedEvent } = {};
        const ENTITY_KEY_RECOMMENDED_EVENTS = 'recommended_events';

        const userSavedEvents = isAuthenticated
            ? await getUserSavedEvents()
            : [];

        const sortedUserSavedEvents = sortBy(
            userSavedEvents.events,
            (event) => event.start_date,
        );

        if (
            !isEmpty(sortedUserSavedEvents) &&
            !entityContext.find(
                (entity) => entity.key === ENTITY_KEY_RECOMMENDED_EVENTS,
            )
        ) {
            sortedUserSavedEvents.forEach((event: FormattedEvent) => {
                entities[event.id || ''] = event;
            });

            entityContext.push({
                key: ENTITY_KEY_RECOMMENDED_EVENTS,
                type: 'event',
                name: gettext('My liked events'),
                events: sortedUserSavedEvents.map(
                    (event: FormattedEvent) => event.id,
                ),
            });

            dispatch(
                updateContent({
                    entityContext,
                    entities,
                }),
            );
        }

        dispatch(setSearchCallAsSuccess());
    };

// Fetch events from Search endpoint
export const fetchEventsForTab = ({
    locationData,
    tabKey = ALL_TAB,
    filters,
}: {
    locationData?: Location;
    tabKey: string;
    filters?: Array<object>;
}) => {
    if (tabKey === FOR_YOU) {
        return fetchEventsForYouTab({
            tabKey,
            filters,
        });
    }

    return fetchEventsForRegularTab({
        locationData: locationData || {},
        tabKey,
    });
};
