import {
    DatingInHomepageExperiment,
    EventSearch,
    EventsNearYouCarouselExperiment,
    useGetStatsigEnrollment,
} from '@eventbrite/discover-utils';
import { Calendar as CalendarSVG } from '@eventbrite/eds-iconography';
import { Layout } from '@eventbrite/eds-layout';
import { gettext } from '@eventbrite/i18n';
import { logEvent } from '@eventbrite/statsig';
import { $FixMe } from '@eventbrite/ts-utils';
import loadable from '@loadable/component';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useIntersection } from 'react-use';
import { DateFilter } from '../../../../constants/dates';
import { NewOrleansPlaceId } from '../../../../constants/seasonalFeatured';
import { ALL_TAB, FOR_YOU } from '../../../../constants/tabConfig';
import { PaidEventsCarousel } from '../../../../containers/PaidEventsCarousel';
import {
    ConnectedProps,
    DiscoverState,
    FeedConfigShape,
} from '../../../../types/index';
import { formatBucketLabelFromKey } from '../../../../utils/buckets';
import { EmptyState } from '../../components/EmptyState';
import { LoadingBucket } from '../../components/LoadingBucket';
import { HEAP_SCROLLED_TO_BOTTOM } from '../../constants/analytics';
import { getBucketsContent, getFlatBucketContent } from '../../redux/content';
import { buildBuckets, getBuckets } from '../../utils';
import { BucketContainer } from '../BucketContainer';
import { LazyLoadedCollectionsBucket } from '../CollectionsBucketContainer';
import { DatingCarousel } from '../DatingCarousel';
import { EventsNearYouCarousel } from '../EventsNearYouCarousel';
import PopularEventsCarousel from '../PopularEventsCarousel';
import { SeasonalCarousel } from '../SeasonalCarousel';
import './FeedBuilderContainer.scss';

const StateMessage = loadable(() => import('../../components/StateMessage'), {
    resolveComponent: (components: { StateMessage: React.ReactElement }) =>
        components.StateMessage,
});

const THRESHOLDS = [0, 0.05, 0.1, 0.15];

const useIsInWindow = () => {
    const [isInWindow, setIsInWindow] = React.useState(false);
    const intersectionRef = React.useRef(null);

    const intersection = useIntersection(intersectionRef, {
        root: null,
        rootMargin: '0px',
        threshold: THRESHOLDS,
    });

    const isIntersecting = intersection?.intersectionRatio >= 0.05;

    useEffect(() => {
        if (isIntersecting) {
            setIsInWindow(true);
        }
    }, [isIntersecting]);

    return {
        ref: intersectionRef,
        isInWindow,
    };
};

interface OwnProps {
    feedConfig: FeedConfigShape;
    eventSearch: EventSearch;
}

const FeedBuilderTabs = (props: FeedBuilderTabsProps) => {
    const {
        tabKey,
        buckets,
        events,
        featureFlags,
        feedConfig,
        loadingState: {
            isFeLoading = true,
            isSearchLoading = true,
            isCityBrowseLoading = true,
            isSearchCallTimeout = false,
        } = {},
        isAuthenticated,
        placeId,
        homepageSeasonalCarouselVariant,
        locationSlug,
        locale,
        location,
    } = props;

    const { isInWindow, ref } = useIsInWindow();
    const { isInWindow: bottomIsInWindow, ref: bottomPageRef } =
        useIsInWindow();
    const [bottomTracked, setBottomTracked] = useState(false);
    const isForYou = tabKey === FOR_YOU;

    const datingInHomepageExperiment = useGetStatsigEnrollment({
        name: DatingInHomepageExperiment.name,
        paramName: DatingInHomepageExperiment.paramName,
        defaultValue: DatingInHomepageExperiment.values.control,
    });

    const { variant } = datingInHomepageExperiment;

    const eventsNearYouCarouselExperiment = useGetStatsigEnrollment({
        name: EventsNearYouCarouselExperiment.name,
        paramName: EventsNearYouCarouselExperiment.paramName,
        defaultValue: EventsNearYouCarouselExperiment.values.control,
    });

    const showEventsNearYouCarousel =
        eventsNearYouCarouselExperiment.variant ===
        EventsNearYouCarouselExperiment.values.test;

    const timeoutEmptyState = (
        <EmptyState
            bucketKey={FOR_YOU}
            emptyState={[
                {
                    validation: {
                        shouldShowEmptyState: true,
                    },
                    config: {
                        title: gettext("We couldn't find anything"),
                        iconType: 'Ghost',
                        iconSize: 'large',
                        description: gettext(
                            "Sorry, we're experiencing some issues right now. Try again later.",
                        ),
                    },
                },
            ]}
        />
    );

    let renderedBuckets: React.ReactNode = [
        <div className="feed-events-bucket" key="loading-component">
            <div
                data-testid="feed-events-bucket-content"
                className="feed-events-bucket__content feed-events-bucket__content--loading"
            >
                <LoadingBucket />
            </div>
        </div>,
    ];

    // track scroll to bottom
    if (bottomIsInWindow && !bottomTracked) {
        setBottomTracked(true);
        logEvent(HEAP_SCROLLED_TO_BOTTOM);
    }

    // Only show this when in browser, not
    //server, to avoid hydration mismatch
    if (!isFeLoading && isInWindow) {
        const allFlatBuckets = buildBuckets({
            events,
            buckets,
            feedConfig,
            isCityBrowseLoading,
        });

        const allBuckets = isForYou
            ? buckets
            : getBuckets({
                  buckets: allFlatBuckets,
                  featureFlags,
                  feedConfig,
              });

        if (allBuckets.length === 0) {
            // The server is temporarily unable to return events
            return (
                <StateMessage
                    title={gettext('Unable to load events')}
                    description={gettext('Try again or check back later')}
                    iconType={<CalendarSVG />}
                    iconSize="medium"
                    primaryLink="/"
                    primaryText={gettext('Try again')}
                />
            );
        }

        if (!isSearchLoading) {
            const allBucketsContent = allBuckets.filter(
                (bucket: { key: string }) =>
                    'events' in bucket || 'profiles' in bucket,
            );

            if (allBucketsContent.length > 0) {
                renderedBuckets = allBucketsContent.reduce(
                    (
                        memo: $FixMe,
                        { key, type, bucketConfig, ...bucketProps },
                        index,
                    ) => {
                        const lastIndex = allBucketsContent.length - 1;
                        const separator =
                            index < lastIndex ? (
                                <hr
                                    key={`separator-${key}`}
                                    className="feed-events-bucket__separator"
                                />
                            ) : null;

                        if (type === 'event' || type === 'profile') {
                            return [
                                ...(memo[Symbol.iterator] ? memo : []),
                                <BucketContainer
                                    key={`${type}-${key}`}
                                    type={type}
                                    bucketConfig={bucketConfig}
                                    bucketKey={key}
                                    // Always show see more for "Organizers you follow"
                                    shouldShowSeeMore={
                                        (index === lastIndex ||
                                            key === 'followed_profiles') &&
                                        !isForYou
                                    }
                                    featureFlags={featureFlags}
                                    bucketIndex={index}
                                    tabKey={tabKey}
                                    lastBucket={index === lastIndex}
                                    {...bucketProps}
                                />,
                                tabKey === ALL_TAB && index === 0 ? (
                                    <LazyLoadedCollectionsBucket
                                        key={`collection-${key}`}
                                    />
                                ) : null,
                                separator,
                            ];
                        }

                        return memo;
                    },
                    [],
                );
            } else if (!isAuthenticated && !allBucketsContent.length) {
                renderedBuckets = (
                    <EmptyState
                        bucketKey={FOR_YOU}
                        emptyState={[
                            {
                                validation: {
                                    shouldShowEmptyState: true,
                                },
                                config: {
                                    title: gettext("Let's make it personal"),
                                    iconType: 'EmptyState',
                                    iconSize: 'xlarge',
                                    description: gettext(
                                        'Log in and tell us what you love to get event picks based on your interests',
                                    ),
                                    primaryText:
                                        gettext('Get started').toString(),
                                    primaryLink: '/login/?referrer=%2F',
                                    buttonStyle: 'fill',
                                },
                            },
                        ]}
                    />
                );
            } else if (isSearchCallTimeout) {
                // Timeout reached for search service calls
                renderedBuckets = timeoutEmptyState;
            } else {
                <EmptyState
                    bucketKey={FOR_YOU}
                    emptyState={[
                        {
                            validation: {
                                shouldShowEmptyState: true,
                            },
                            config: {
                                title: gettext('No events in your area'),
                                iconSize: 'medium',
                                description: gettext(
                                    'Try a different location',
                                ),
                            },
                        },
                    ]}
                    isAuthenticated={isAuthenticated}
                />;
            }
        }

        if (isSearchCallTimeout) {
            // Timeout reached for search service calls
            renderedBuckets = timeoutEmptyState;
        }
    }
    const bucketLabel = formatBucketLabelFromKey({
        bucketKey: '',
        tabKey,
        name: '',
        currentPlace: '',
    });

    return (
        <>
            {!isAuthenticated && tabKey === ALL_TAB && (
                <PopularEventsCarousel />
            )}
            <Layout hasHorizontalGutters={true} maxWidth="large">
                <div
                    className="feed-builder"
                    ref={ref}
                    data-event-bucket-label={bucketLabel}
                >
                    {showEventsNearYouCarousel &&
                        isAuthenticated &&
                        isForYou && (
                            <EventsNearYouCarousel
                                isAuthenticated={isAuthenticated || false}
                                locale={locale}
                                location={location}
                                affCode="ebdssbehomepeforyou"
                                tab={FOR_YOU}
                            />
                        )}
                    {tabKey === ALL_TAB ? (
                        <>
                            {variant ===
                                DatingInHomepageExperiment.values.test &&
                                locationSlug != 'online' && <DatingCarousel />}
                            {homepageSeasonalCarouselVariant &&
                                placeId == NewOrleansPlaceId && (
                                    <SeasonalCarousel season="Mardi Gras" />
                                )}

                            {!props.isMobile && (
                                <PaidEventsCarousel
                                    isAuthenticated={isAuthenticated || false}
                                    locale={locale}
                                    isMobile={false}
                                    location={location}
                                    page="homepage"
                                    affCode="ehometext"
                                />
                            )}
                            {showEventsNearYouCarousel && (
                                <EventsNearYouCarousel
                                    isAuthenticated={isAuthenticated || false}
                                    locale={locale}
                                    location={location}
                                    affCode="ehometext"
                                    tab={ALL_TAB}
                                />
                            )}
                            {renderedBuckets}
                        </>
                    ) : (
                        renderedBuckets
                    )}

                    <div ref={bottomPageRef}></div>
                </div>
            </Layout>
        </>
    );
};

const _mapStateToProps = ({
    app,
    content,
    user,
    location,
    env,
}: DiscoverState) => {
    return {
        buckets: getBucketsContent(content),
        events: getFlatBucketContent(
            content.browseState?.tabKey || '',
            content.flatBucket,
        ),
        featureFlags: app.featureFlags,
        isMobile: env.isMobile,
        loadingState: app.loadingState,
        tabKey: content.browseState?.tabKey,
        isAuthenticated: user.isAuthenticated,
        placeId: location.placeId,
        location: location,
        locale: app.locale,
        firstName: user.firstName,
    };
};

type ReduxProps = ConnectedProps<typeof _mapStateToProps>;
type FeedBuilderTabsProps = ReduxProps &
    OwnProps & {
        homepageSeasonalCarouselVariant?: boolean | string;
        locale: string;
        firstName: string;
        dateFilter: DateFilter;
        locationSlug: string;
    };

export default connect(_mapStateToProps)(FeedBuilderTabs);
